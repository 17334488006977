import { useState } from "react";
import React, { useRef } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import emailjs from '@emailjs/browser';

export const Contact = () => {

  const form = useRef();
 
  // const [formState, setFormState] =useState({});


  // const changeHandler = (event) =>{
  //   setFormState({...formState,[event.target.firstName]: event.target.value})
  // }

  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
      setFormDetails({
        ...formDetails,
        [category]: value
      })
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_0r305zp', 'template_rx9qjon', form.current, {
        publicKey: 'R01AsP14BX1mipGEi',
      })
      .then(
        () => {
          form.current.reset();
          let emptyvals = {  firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: '' };
          setFormDetails(emptyvals);
          console.log(form.current.value);
        },
        (error) => {
          console.log(this.refs.from_name.value);
          // form.current.firstName.value='';
          console.log('FAILED...', error.text);
        },
      );
  };

  // const handleSubmit = async (e) => {
  //    e.preventDefault();

  //   const config = {
  //     Username: 'sysbus@yopmail.com',
  //     Passwrord: '5870E6C62CB1613B4B97E0D2F536663A3279',
  //     Host: 'smtp.elasticemail.com',
  //     Port: '2525',
  //     // SecureToken: '7a980130-5910-4fe7-ac13-5e19072b5fe2',
  //     To : 'sysbus@yopmail.com',
  //     From : formDetails.email,
  //     Subject : "This is the subject",
  //     Body : `${formDetails.firstName} ${formDetails.phone} connected with you over email with message as ${formDetails.message}`
  //   }

  //   if(window.Email){
  //     window.Email.send(config).then(
  //       () => alert("message"));
  //   }
  //   // e.preventDefault();
  //   // setButtonText("Sending...");
  //   // let response = await fetch("http://localhost:5000/contact", {
  //   //   method: "POST",
  //   //   headers: {
  //   //     "Content-Type": "application/json;charset=utf-8",
  //   //   },
  //   //   body: JSON.stringify(formDetails),
  //   // });
  //   // setButtonText("Send");
  //   // let result = await response.json();
  //   // setFormDetails(formInitialDetails);
  //   // if (result.code == 200) {
  //   //   setStatus({ succes: true, message: 'Message sent successfully'});
  //   // } else {
  //   //   setStatus({ succes: false, message: 'Something went wrong, please try again later.'});
  //   // }
  // };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Get In Touch</h2>
                <h5>Ready to take your business to the next level? Contact us today for a free consultation!</h5>
                
                <form ref={form} onSubmit={sendEmail}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" ref="from_name" name="from_name" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" name="from_last_name" value={formDetails.lasttName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)}/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" name="phone" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" name="message" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                      <button type="submit"><span>{buttonText}</span></button>
                    </Col>
                    {
                      status.message &&
                      <Col>
                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                      </Col>
                    }
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}