import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/bg2.jpg"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Why Choose Us?</h2>
              <ul>
                <li><b>Experienced Team:</b> Team comprises experienced bookkeepers and software
                  implementation specialists committed to delivering top-notch services.</li>
                <li><b>Customized Solutions:</b> We understand that every business is unique. That’s why we
                  offer tailored services to meet your specific needs.</li>
                <li><b>Secure and Confidential:</b> Your financial information is safe with us. We adhere to
                  strict confidentiality and security measures to protect your data.</li>
                <li><b>Client-Centric Approach:</b>We value our clients and strive to provide exceptional
                  customer service. We work closely with you to understand your business needs and
                  deliver results-driven solutions.</li>
                <li><b>Quality Assurance:</b> We adhere to high standards of quality and ensure that the
                  software we implement and integrate adds value to your business.</li>
                <li><b>Customer Support:</b>We offer exceptional customer support, ensuring that any issues
                  you encounter are resolved promptly</li>
              </ul>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text.</p> */}
              <h4>Client Testimonials</h4>
              <p className="testName">Please read what our clients have to say about our services!</p>
             
              <Carousel responsive={responsive} infinite={true} className="">
                <div className="items">
                  <p>“Fast and efficient service from accommodating staff. They always got back to me straight away and
                    gave me the best information on how to lodge my taxes and structure my invoicing. This has made
                    my business much easier to run. Excellent service and I would recommend it to anyone.”</p>
                  <h5>Andy Nisbet</h5>
                </div>
                <div className="items">
                  <p>“Excellent service. Sebastian has been a pleasure to deal with and has kept me well-informed.
                    I would recommend it to anyone looking for tax solutions.”</p>
                  <h5>Josh</h5>
                </div>
                <div className="items">
                  <p>“Seb is the best at what he does, an expert in all aspects of accounting, Xero professional; he saved
                    my friend's company with all bookkeeping and tax return updates and bas!!! Very focused man and
                    responsible at his role.”</p>
                  <h5>Jenny Lee</h5>
                </div>
                <div className="items">
                  <p>“Sebastian was awesome. He was easy to understand and patient. He helped us out with some
                    training and fixed things up. We'll be back to him for our next session soon.”</p>
                  <h5>Paul – Frankston South, VIC</h5>
                </div>
                <div className="items">
                  <p>Very efficient and reasonably priced, I would use it again!</p>
                  <h5>Chris</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}