import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/bg2.jpg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg2,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg3,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg2,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg3,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>What We Do?</h2>
                  <h4>Software Implementation</h4>
                  <p>Our software implementation services aim to guarantee that your software solutions are
                    installed, configured, and operating at their best. We follow a systematic approach that
                    comprises the following steps:</p>
                  <ul>
                    <li><b>Requirement Analysis:</b><span>We work closely with you to understand your business needs
                      and software requirements.</span></li>
                    <li><b>System Design and Installation:</b><span>We work closely with you to understand your business needs
                      and software requirements.</span></li>
                    <li><b>Testing:</b><span>We work closely with you to understand your business needs
                      and software requirements.</span></li>
                    <li><b>Deployment:</b><span>We work closely with you to understand your business needs
                      and software requirements.</span></li>
                    <li><b>Training and Support:</b><span>We work closely with you to understand your business needs
                      and software requirements.</span></li>
                  </ul>

                  <h4>Software Integration</h4>
                  <p>Our software integration services aim to create a cohesive system where all your software
                    applications work together seamlessly. This includes:</p>
                  <ul>
                    <li><b>Integration Planning:</b><span>We identify the software applications to be integrated and plan the
                      process.</span></li>
                    <li><b>System Integration:</b><span>We use the latest tools and technologies to integrate the systems
                      effectively.</span></li>
                    <li><b>Testing:</b><span>The integrated system is thoroughly tested for performance and functionality.</span></li>
                    <li><b>Deployment and Support:</b><span>The system is deployed, and we provide ongoing support to
                      ensure it continues to operate efficiently.</span></li>
                  </ul>
                  {/* <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Tab 1</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Tab 2</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Tab 3</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {
                            projects.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="section">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container> */}
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      {/* <img className="background-image-right" src={colorSharp2}></img> */}
    </section>
  )
}