import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const About = () => {
    return (
        <section className="about" id="skills">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="aboutClass">
                            <h1>About us</h1>
                            <p>
                                We are a team of certified bookkeepers and software implementation specialists with years of
                                experience in the industry. Our passion is to help businesses like yours navigate the
                                complexities of financial management and digital transformation. We believe in the power of
                                technology to drive business growth, and we are committed to bringing you the best solutions
                                to meet your unique needs.
                            </p>
                            <h3>Expert Bookkeeping Services:</h3>
                            <p>
                                Our team of certified bookkeepers is committed to maintaining the accuracy of your financial
                                records. We understand that every business is unique, so we offer customised bookkeeping
                                services catering to your needs. We can manage accounts payable and receivable, prepare
                                financial statements, provide payroll services, prepare taxes, and provide economic
                                forecasting to help you make informed business decisions.
                            </p>

                            <h3>Software Implementation and Integrations for Efficiency:</h3>
                            <p>
                                Using the right software can make all the difference in your business operations in the digital
                                age. Our experts can help you select and implement the best software solutions to automate
                                your business processes, improve data accuracy, and generate insightful reports. We
                                specialise in implementing accounting software, CRM systems, and project management
                                tools. We also provide training and ongoing support to ensure you get the most out of your
                                software investment.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            
        </section>
        // <footer className="footer">
        //     <Container>
        //         <Row className="align-items-center">


        //         </Row>
        //     </Container>
        // </footer>
    )
}